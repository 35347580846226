export const Weight = {
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
};

export const LineSpace = {
  S0: 0,
  S30: 0.3,
  S40: 0.4,
};

export const WhiteSpace = {
  Normal: 'normal',
  Pre: 'pre',
  Nowrap: 'nowrap',
  PreWrap: 'pre-wrap',
  PreLine: 'pre-line',
  BreakSpaces: 'break-spaces',
};

export const TextAlign = {
  Left: 'left',
  Right: 'right',
  Center: 'center',
  Justify: 'justify',
};

export const Size = {
  P10: '0.625rem',
  P12: '0.75rem',
  P14: '0.875rem',
  P16: '1rem',
  P18: '1.125rem',
  P20: '1.25rem',
  P21: '1.313rem',
  P22: '1.375rem',
  P24: '1.5rem',
  P26: '1.625rem',
  P27: '1.75rem',
  P28: '1.75rem',
  P30: '1.875rem',
  P36: '2.25rem',
  P40: '2.5rem',
  P42: '2.625rem',
  P45: '2.813rem',
};

export const Preset = {
  Display1: {
    fontSize: Size.P30,
    fontWeight: Weight.Bold,
    lineHeight: Size.P45,
  },
  Display2: {
    fontSize: Size.P28,
    fontWeight: Weight.Bold,
    lineHeight: Size.P42,
  },
  Head1: {
    fontSize: Size.P24,
    fontWeight: Weight.Bold,
    lineHeight: Size.P36,
  },
  Head2: {
    fontSize: Size.P20,
    fontWeight: Weight.Bold,
    lineHeight: Size.P30,
  },
  Head3Bold: {
    fontSize: Size.P18,
    fontWeight: Weight.Bold,
    lineHeight: Size.P27,
  },
  Head3: {
    fontSize: Size.P18,
    fontWeight: Weight.Medium,
    lineHeight: Size.P24,
  },
  Body1Bold: {
    fontSize: Size.P16,
    fontWeight: Weight.Bold,
    lineHeight: Size.P24,
  },
  Body1: {
    fontSize: Size.P16,
    fontWeight: Weight.Regular,
    lineHeight: Size.P24,
  },
  Body2: {
    fontSize: Size.P14,
    fontWeight: Weight.Regular,
    lineHeight: Size.P21,
  },
  Body3: {
    fontSize: Size.P12,
    fontWeight: Weight.Regular,
    lineHeight: Size.P16,
  },
  Body4: {
    fontSize: Size.P10,
    fontWeight: Weight.Regular,
    lineHeight: Size.P14,
  },
};

export default {
  Weight,
  LineSpace,
  WhiteSpace,
  TextAlign,
  Size,
  Preset,
};
