import { createContext, useState } from 'react';
import DialogModal, { DialogConfig } from 'components/useCases/DialogModal';
import { DialogContextValues } from './types';

const defaultDialogConfig: DialogConfig = {
  title: '알림',
  primaryText: '확인',
  destructiveText: '취소',
};

const DialogContext = createContext<DialogContextValues>({});

const DialogProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [isOpen, setOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>(defaultDialogConfig);

  const closeDialog = () => {
    setOpen(false);
    setDialogConfig({
      ...defaultDialogConfig,
      onPrimary: closeDialog,
      onDestructive: closeDialog,
    });
  };

  const openDialog = (dialogConfig) => {
    setOpen(true);
    setDialogConfig({
      ...defaultDialogConfig,
      onPrimary: closeDialog,
      ...dialogConfig,
    });
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <DialogModal open={isOpen} dialogConfig={dialogConfig} onCleanup={closeDialog} />
    </DialogContext.Provider>
  );
};

export { DialogContext, DialogProvider };
