import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';

import * as amplitude from '@amplitude/analytics-browser';
import { HackleProvider, createInstance as createHackleInstance } from '@hackler/react-sdk';
import useUserAgent from 'hooks/useUserAgent';
import { postCommandMessage, PostMessageCommandKey } from 'libs/bridge';
import { convertObjectFlatten } from 'utils/convert-structure';
import { Union } from 'utils/types';
import { UserContext } from './UserContext';
import { FunnelContext } from './FunnelContext';

const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '';
if (typeof window !== 'undefined') {
  amplitude.init(amplitudeApiKey, undefined, { defaultTracking: true });
}

const hackleSdkKey = process.env.NEXT_PUBLIC_HACKLE_SDK_KEY || '';
const hackleClient = createHackleInstance(hackleSdkKey, {
  debug: process.env.NODE_ENV === 'development',
  auto_track_page_view: true,
});

export const TrackingEventName = Object.freeze({
  // home ------------------------------------------------------------------------------------
  clinicHomeNewProductClick: 'clinic_home_newProduct_click', // 홈에서 신규 이벤트 버튼 클릭 했을 때
  homeToplistProductClick: 'clinic_home_toplistProduct_click',
  homeToplistFilterClick: 'clinic_home_toplistFilter_click',
  homeToplistImpression: 'clinic_home_toplist_impression',
  productAddwishlistClick: 'clinic_product_addwishlist_click',
  // 신규 이벤트 ------------------------------------------------------------------------------------
  clinicNewProductListView: 'clinic_newProductList_view', // 신규 이벤트 Page View
  clinicNewProductListEmptyView: 'clinic_newProductList_empty_view', // 신규 이벤트 목록이 비었을 때
  clinicNewProductListImpression: 'clinic_newProductList_impression', // 시술혜택 신규이벤트 목록 페이지 스크롤(10개 단위)
  clinicNewProductListProductClick: 'clinic_newProductList_product_click', // 시술혜택 신규이벤트 상품 클릭
  // 이벤트 상세 -------------------------------------------------------------------------------------------
  eventDetailPageView: 'health_clinic_productDetail_view', // 이벤트 상세화면 Page View
  eventDetailContentLoadFail: 'clinic_eventDetail_contentLoad_fail', // 이벤트 상세화면 컨텐츠 로드 실패
  eventDetailSubmitClick: 'health_clinic_productDetail_submit_click', // 이벤트 상세화면에서 신청하기 버튼 클릭
  eventDetailSimilarEventClick: 'clinic_eventDetail_similarEvent_click', // 이벤트 상세화면에서 연관 이벤트 클릭
  eventDetailShowMoreClick: 'clinic_eventDetail_showMore_click', // 이벤트 상세화면에서 더보기 버튼 클릭
  eventDetailWishClick: 'health_clinic_productDetail_like_click', // 이벤트 상세화면에서 북마크아이콘 클릭
  eventDetailHospitalInfoClick: 'clinic_eventDetail_hospitalInfo_click', // 이벤트 상세화면에서 병원정보 클릭
  eventDetailHospitalReviewClick: 'clinic_eventDetail_hospitalReview_click', // 이벤트 상세화면에서 리뷰 클릭
  // 이벤트 신청 -------------------------------------------------------------------------------------------
  eventSubmitFormView: 'clinic_submitForm_view', // 이벤트 신청화면 Page View
  eventSubmitFormSignUpClick: 'clinic_submitForm_signUp_click', // 이벤트 신청화면에서 회원가입 버튼 클릭
  eventSubmitFormSubmitComplete: 'clinic_submitForm_submit_complete', // 이벤트 신청화면에서 신청완료
  eventSubmitFormSubmitClick: 'health_clinic_eventDetail_request_click', // 이벤트 신청화면에서 신청하기 버튼 클릭
  eventSubmitFormCompleteMoreClick: 'clinic_submitFormComplete_more_click', // 이벤트 신청완료화면에서 더보기 버튼 클릭
  eventSubmitFormCompleteCheckClick: 'clinic_submitFormComplete_check_click', // 이벤트 신청완료화면에서 체크하기 버튼 클릭
  eventSubmitFormInfoInput: 'clinic_submitForm_info_input', // 이벤트 신청화면에서 정보입력 업데이트
  // 찜 -------------------------------------------------------------------------------------------
  clinicHomeWishlistIconClick: 'clinic_home_wishlistIcon_click', // 우측상단 찜하기 아이콘 클릭
  clinicHomeWishlistMainClick: 'clinic_home_wishlistMain_click', // 메인찜하기 타이틀 > 클릭
  clinicHomeWishlistIconProductClick: 'clinic_home_wishlistIcon_product_click', // 찜하기 리스트 이벤트 개별 클릭
  clinicHomeWishlistIconProductCancel: 'clinic_home_wishlistIcon_product_cancel', // 찜하기 리스트 내 이벤트 찜하기 취소
  clinicHomeWishlistIconMoreBtnClick: 'clinic_home_wishlistIcon_moreBtn_click', // 30개 이상 시, 더보기 버튼 클릭
  clinicHomeWishlistMainView: 'clinic_home_wishlistMain_view', // 메인찜하기 노출
  clinicHomeWishlistMainProductClick: 'clinic_home_wishlistMain_product_click', // 메인찜하기 이벤트 클릭
  clinicHomeWishlistMainMoreClick: 'clinic_home_wishlistMain_more_click', // 메인찜하기 전체보기 클릭
  // 기획전 -------------------------------------------------------------------------------

  exhibition2306PageView: 'clinic_exhibition2306_page_view', // 6월 주차별 기획전 랜딩 페이지 노출
  exhibition2306EventCardClick: 'clinic_exhibition2306_event_click', // 6월 주차별 기획전 상품 클릭
  exhibition2306MoreButtonClick: 'clinic_exhibition2306_more_click', // 6월 주차별 기획전 더보기 버튼 클릭
  exhibition2306ShareButtonClick: 'clinic_exhibition2306_share_click', // 6월 주차별 기획전 공유하기 버튼 클릭
  exhibition2306EventsImpression: 'clinic_exhibition2306_events_impression', // 6월 주차별 기획전 스크롤 (이벤트 카드 영역까지 스크롤 여부)

  orientalMedicalPageView: 'clinic_oriental_page_view', // 한방 기획전 페이지 접속
  orientalMedicalMoreButtonClick: 'clinic_oriental_more_click', // 한방 기획전 더보기 버튼 클릭
  orientalMedicalEventCardClick: 'clinic_oriental_event_click', // 한방 기획전 상품 클릭
  orientalMedicalShareButtonClick: 'clinic_oriental_share_click', // 한방 기획전 공유하기 버튼 클릭
  orientalMedicalEventsImpression: 'clinic_oriental_events_impression', // 한방 기획전 스크롤 (이벤트 카드 영역까지 스크롤 여부)
  orientalMedicalCommentImpression: 'clinic_oriental_comment_impression', // 한방 기획전 스크롤 (댓글 영역까지 스크롤 여부)
  orientalMedicalCommentSubmitButtonClick: 'clinic_oriental_comment_submit', // 한방 기획전 댓글 등록 버튼 클릭
  orientalMedicalCommentInvalidLength: 'clinic_oriental_comment_invalidLength', // 한방 기획전 댓글 길이 유효성 검증
  orientalMedicalCommentDeleteButtonClick: 'clinic_oriental_comment_delete', // 한방 기획전 댓글 삭제 버튼 클릭
  orientalMedicalCommentMoreLoadButtonClick: 'clinic_oriental_comment_load', // 한방 기획전 댓글 더보기 버튼 클릭
  orientalMedicalCommentSignIn: 'clinic_oriental_comment_signIn', // 한방 기획전 댓글 비로그인 유저 로그인
});
export type TrackingEventNameType = Union<typeof TrackingEventName>;

interface AnalyticsContextValues {
  trackEvent?: (name: string | TrackingEventNameType, properties?: any) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContextValues>({});
export interface AnalyticsContextProps {}
const AnalyticsProvider: FC<PropsWithChildren<AnalyticsContextProps>> = (props) => {
  const { children } = props;

  const { auth, user, device, isSignedIn } = useContext(UserContext);
  const { funnel } = useContext(FunnelContext);
  const { isMobile } = useUserAgent();

  const analyticsUser = useMemo(
    () => ({
      id: device?.id,
      deviceId: device?.id,
      userId: auth?.userId?.toString(),
      properties: {
        deviceOS: device?.os,
        appVersion: device?.appVersion,
        birth: user?.birth,
        gender: user?.gender,
        zipCode: user?.zipCode,
        loginStatus: isSignedIn,
        userId: auth?.userId?.toString(),
      },
    }),
    [device, isSignedIn, user, auth?.userId]
  );

  useEffect(() => {
    amplitudeIdentify();
    hackleIdentify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsUser]);

  const amplitudeIdentify = () => {
    const identifyEvent = new amplitude.Identify();

    const { properties, ...userInfo } = analyticsUser;
    Object.entries({ ...userInfo, ...properties }).forEach(([key, value]) => {
      identifyEvent.set(key, value);
    });
    amplitude.identify(identifyEvent);
  };

  const hackleIdentify = () => {
    if (isSignedIn) {
      hackleClient.setUser(analyticsUser);
    } else {
      hackleClient.resetUser();
    }
  };

  const trackEvent = useCallback(
    (name: string | TrackingEventNameType, properties?: any) => {
      const propertyValues = convertObjectFlatten(properties ? { ...properties, funnel } : { funnel });

      if (process.env.NODE_ENV === 'development') console.log('[Analytics] trackEvent', name, propertyValues);

      if (isMobile) {
        postCommandMessage(PostMessageCommandKey.analyticsTrackEvent, { event: name, values: propertyValues });
      } else {
        amplitude.track(name, propertyValues);
        hackleClient.track({ key: name, properties: propertyValues });
      }
    },
    [funnel, isMobile]
  );

  return (
    <AnalyticsContext.Provider
      value={{
        trackEvent,
      }}
    >
      <HackleProvider hackleClient={hackleClient} supportSSR>
        {children}
      </HackleProvider>
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsProvider };
