import { ReactNode, FC, useState, createContext, Dispatch, SetStateAction } from 'react';

type HeaderTitleType = string | ReactNode;
interface HeaderContextValue {
  title?: HeaderTitleType;
  setTitle?: Dispatch<SetStateAction<HeaderTitleType>>;
  cartBadgeCount?: number;
  setCartBadgeCount?: Dispatch<SetStateAction<number>>;
  onBackButtonClicked?: () => void;
  setBackButtonClicked?: Dispatch<SetStateAction<() => void>>;
}

const HeaderContext = createContext<HeaderContextValue>({});

const HeaderProvider: FC = (props) => {
  const { children } = props;
  const [title, setTitle] = useState<HeaderTitleType>('');
  const [onBackButtonClicked, setBackButtonClicked] = useState<() => void>(null);
  const [cartBadgeCount, setCartBadgeCount] = useState(0);

  return (
    <HeaderContext.Provider
      value={{
        title,
        setTitle,
        cartBadgeCount,
        setCartBadgeCount,
        onBackButtonClicked,
        setBackButtonClicked,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
