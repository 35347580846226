import { gql } from '@apollo/client';

export const CREATE_EVENT_CART = gql`
  mutation createEventCart($eventId: ID!) {
    createEventCart(eventId: $eventId) {
      id
      clinicEventId
    }
  }
`;

export const COUNT_EVENT_CARTS = gql`
  query countEventCarts {
    countEventCarts
  }
`;

export const FETCH_RECOMMENDED_EVENTS = gql`
  query getRecommendedEvents($eventId: ID!) {
    getRecommendedEvents(eventId: $eventId) {
      id
      title
      price
      originalPrice
      status
      thumbnailImage
      hospital {
        id
        name
        operatingState
        latitude
        longitude
      }
    }
  }
`;

export const FETCH_EVENT_CARTS = gql`
  query getEventCarts {
    getEventCarts {
      id
      lastCallRequestedAt
      clinicEvent {
        id
        title
        price
        status
        thumbnailImage
        startAt
        endAt
        hospital {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_CALL_REQUESTS = gql`
  mutation createCallRequests($data: EventCallRequestsInput!) {
    createCallRequests(data: $data) {
      failCount
      successCount
      failedRequests {
        eventId
        failMessage
        eventName
      }
    }
  }
`;

export const CREATE_GUEST_CALL_REQUESTS = gql`
  mutation createGuestCallRequests($data: EventCallRequestsInput!) {
    createGuestCallRequests(data: $data) {
      failCount
      successCount
      failedRequests {
        eventId
        failMessage
        eventName
      }
    }
  }
`;

export const DELETE_EVENT_CARTS = gql`
  mutation deleteEventCarts($eventIds: [String!]!) {
    deleteEventCarts(eventIds: $eventIds)
  }
`;
