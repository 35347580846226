import { CSSProperties } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import constate from 'constate';

interface ToastContextValues {
  showToastMessage?: (message: string, customStyle: CSSProperties, variant: VariantType) => void;
  showError?: (message: string, customStyle?: CSSProperties) => void;
  showInfo?: (message: string, customStyle?: CSSProperties) => void;
  showSuccess?: (message: string, customStyle?: CSSProperties) => void;
}

const useToast = (): ToastContextValues => {
  const { enqueueSnackbar } = useSnackbar();

  const showToastMessage = (message: string, customStyle: CSSProperties = {}, variant: VariantType = 'default') => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      variant,
      style: {
        ...customStyle,
      },
    });
  };

  const showError = (message: string, customStyle: CSSProperties = {}) => {
    showToastMessage(message, customStyle, 'error');
  };

  const showInfo = (message: string, customStyle: CSSProperties = {}) => {
    showToastMessage(message, customStyle);
  };

  const showSuccess = (message: string, customStyle: CSSProperties = {}) => {
    showToastMessage(message, customStyle, 'success');
  };

  return {
    showToastMessage,
    showError,
    showInfo,
    showSuccess,
  };
};

const [ToastProvider, useToastContext] = constate(useToast);

export { useToastContext, ToastProvider };
