/* eslint-disable eqeqeq */
import constate from 'constate';
import dayjs from 'dayjs';
import { OperationVariables, QueryLazyOptions, useLazyQuery, useMutation } from '@apollo/client';
import { apolloClinicLink } from 'graphql/apollo';
import { COUNT_EVENT_CARTS, CREATE_EVENT_CART, FETCH_EVENT_CARTS, FETCH_RECOMMENDED_EVENTS } from 'graphql/eventCart';
import { Dispatch, SetStateAction, useContext, useEffect, useState, useCallback } from 'react';
import {
  CountEventCarts,
  EventCart,
  ClinicEvent,
  LacteaClinicEvent,
  LacteaHospital,
  LacteaCallRequest,
} from 'model/graphql';
import { DialogContext } from 'contexts/DialogContext';
import { LOCAL_STORAGE_KEY_CLINIC_EVENT_CART, SESSION_STORAGE_KEY_CLINIC_EVENT_REQUESTED } from 'types/constants';
import { StatusCodes } from 'http-status-codes';
import { isLiveClinicEvent } from 'utils/validation';
import { UserContext } from './UserContext';
import { useToastContext } from './ToastContext';
import { HeaderContext } from './HeaderContext';
import { AnalyticsContext } from './AnalyticsContext';

interface EventCartContext {
  addToEventCart: (
    lacteaEvent: LacteaClinicEvent | ClinicEvent,
    lacteaHospital?: LacteaHospital,
    needsRefresh?: boolean
  ) => void;
  createdEventCart: EventCart;
  createEventCartLoading: boolean;
  recommendedEvents: ClinicEvent[];
  setRecommendedEvents: Dispatch<SetStateAction<ClinicEvent[]>>;
  setCreatedEventCart: Dispatch<SetStateAction<EventCart>>;
  refreshBadgeCount: () => void;
  fetchRemoteEventCarts: (options?: QueryLazyOptions<OperationVariables>) => void;
  localEventCarts: EventCart[];
  setLocalEventCarts: Dispatch<SetStateAction<EventCart[]>>;
  remoteEventCarts: EventCart[];
  setRemoteEventCarts: Dispatch<SetStateAction<EventCart[]>>;
  eventCartsLoading: boolean;
  setClinicEventsRequested: (clinicEventsRequested: LacteaCallRequest[]) => void;
  isEventCartAddedAnimation: boolean;
  fetchLocalEventCarts: () => EventCart[];
  hospitalNames: string;
  setHospitalNames: (hospitalNames: string) => void;
}

const useEventCart = (): EventCartContext => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { isSignedIn, isNotSignedIn } = useContext(UserContext);
  const { showError, showInfo } = useToastContext();
  const { setCartBadgeCount } = useContext(HeaderContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [isEventCartAddedAnimation, setEventCartAddedAnimation] = useState(false);

  const [recommendedEvents, setRecommendedEvents] = useState<ClinicEvent[]>();
  const [createdEventCart, setCreatedEventCart] = useState<EventCart>(null);
  const [localEventCarts, setLocalEventCarts] = useState<EventCart[]>();
  const [remoteEventCarts, setRemoteEventCarts] = useState<EventCart[]>();
  const [hospitalNames, setHospitalNames] = useState<string>(null);

  useEffect(() => {
    if (isEventCartAddedAnimation) {
      setTimeout(() => {
        setEventCartAddedAnimation(false);
      }, 0.8 * 1000);
    }
  }, [isEventCartAddedAnimation]);

  const showDuplicationError = useCallback(
    (clinicEvent?: ClinicEvent) => {
      const properties = clinicEvent && {
        productId: clinicEvent.id,
        productName: clinicEvent.title,
        hospitalId: clinicEvent.hospital?.id,
        hospitalName: clinicEvent.hospital?.name,
        loginStatus: isSignedIn,
      };

      openDialog({
        title: '이미 이벤트가 카트에 담겨있어요.',
        message: '같은 이벤트는 카트에 한번만 담을 수 있어요.',
        destructiveText: '확인',
        primaryText: '카트 보기',
        onPrimary: () => {
          console.error('EventCart does not exist anymore');
        },
        onDestructive: () => {
          closeDialog();
        },
      });
    },
    [openDialog]
  );

  // FIXME: cart 관련 call이라 clinic으로 변경하지 않음 (deprecated)
  const [fetchRemoteEventCarts, { data: eventCartsData, loading: eventCartsLoading }] = useLazyQuery<{
    getEventCarts: EventCart[];
  }>(FETCH_EVENT_CARTS, { client: apolloClinicLink, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (isSignedIn) {
      if (!eventCartsLoading) {
        setRemoteEventCarts(eventCartsData?.getEventCarts);
      }
    }
  }, [eventCartsData, eventCartsLoading, isSignedIn]);

  // FIXME: cart 관련 call이라 clinic으로 변경하지 않음 (deprecated)
  const [fetchEventCartCount, { data: eventCartCountData }] = useLazyQuery<CountEventCarts>(COUNT_EVENT_CARTS, {
    client: apolloClinicLink,
    fetchPolicy: 'no-cache',
  });

  const fetchLocalEventCartCount = () => {
    const eventCartsString = localStorage.getItem(LOCAL_STORAGE_KEY_CLINIC_EVENT_CART) || '[]';
    const eventCarts: EventCart[] = JSON.parse(eventCartsString);
    const eventCartsOnlyLive = eventCarts?.filter((eventCart) => isLiveClinicEvent(eventCart.clinicEvent));
    return eventCartsOnlyLive.length || 0;
  };

  // FIXME: cart 관련 call이라 clinic으로 변경하지 않음 (deprecated)
  const [fetchRecommendedEvents, { data: recommendedEventsData, error: recommendedEventsError }] = useLazyQuery<{
    getRecommendedEvents: ClinicEvent[];
  }>(FETCH_RECOMMENDED_EVENTS, {
    client: apolloClinicLink,
    fetchPolicy: 'no-cache',
  });

  const [createEventCart, { loading: createEventCartLoading }] = useMutation<{ createEventCart: EventCart }>(
    CREATE_EVENT_CART,
    {
      client: apolloClinicLink,
      onError: (error) => {
        if (error?.graphQLErrors?.length > 0 && error.graphQLErrors[0].extensions?.code == StatusCodes.CONFLICT) {
          showDuplicationError();
        } else {
          showError(error.message);
        }
      },
      onCompleted: (data) => {
        const { createEventCart } = data;
        fetchRecommendedEvents({ variables: { eventId: createEventCart.clinicEventId } });
        setCreatedEventCart(createEventCart);
        fetchEventCartCount();
        setEventCartAddedAnimation(true);
      },
    }
  );

  const fetchLocalEventCarts = () => {
    const eventCartsString = localStorage.getItem(LOCAL_STORAGE_KEY_CLINIC_EVENT_CART) || '[]';
    const eventCarts: EventCart[] = JSON.parse(eventCartsString);
    setLocalEventCarts(eventCarts);
    return eventCarts;
  };

  useEffect(() => {
    if (!recommendedEventsError) return;
    if (recommendedEventsError?.graphQLErrors?.[0].extensions.code == StatusCodes.NOT_FOUND) {
      setRecommendedEvents([]);
    } else {
      showError(recommendedEventsError?.message);
    }
  }, [recommendedEventsError]);

  useEffect(() => {
    setRecommendedEvents(recommendedEventsData?.getRecommendedEvents);
  }, [recommendedEventsData]);

  useEffect(() => {
    refreshBadgeCount();
  }, [isSignedIn, isNotSignedIn]);

  useEffect(() => {
    setCartBadgeCount(eventCartCountData?.countEventCarts);
  }, [eventCartCountData]);

  const refreshBadgeCount = () => {
    if (isSignedIn) fetchEventCartCount();
    if (isNotSignedIn) {
      setCartBadgeCount(fetchLocalEventCartCount());
    }
  };

  const createLocalEventCart = (clinicEvent: ClinicEvent) => {
    const eventCartsString = localStorage.getItem(LOCAL_STORAGE_KEY_CLINIC_EVENT_CART) || '[]';
    const eventCarts: EventCart[] = JSON.parse(eventCartsString);
    const foundEventCart = eventCarts.find((eventcart) => eventcart.clinicEventId === clinicEvent.id.toString());
    if (foundEventCart) {
      showDuplicationError(foundEventCart.clinicEvent);
    } else {
      const eventCart: EventCart = {
        id: new Date().getTime().toString(),
        clinicEventId: clinicEvent.id.toString(),
        regDate: dayjs().format('YYYY-MM-DD hh:mm:ss'),
        clinicEvent,
      };
      eventCarts.sort((a: EventCart, b: EventCart) => b.id.localeCompare(a.id));
      localStorage.setItem(LOCAL_STORAGE_KEY_CLINIC_EVENT_CART, JSON.stringify([eventCart, ...eventCarts]));
      fetchRecommendedEvents({ variables: { eventId: clinicEvent.id } });
      setCreatedEventCart(eventCart);
      setCartBadgeCount(fetchLocalEventCartCount());
      setEventCartAddedAnimation(true);
    }
  };

  const addToEventCart = async (clinicEvent: ClinicEvent, lacteaHospital?: LacteaHospital, needsRefresh = false) => {
    if (isSignedIn) {
      await createEventCart({ variables: { eventId: clinicEvent.id } });
      if (needsRefresh) {
        showInfo('이벤트를 카트에 담았어요');
        fetchRemoteEventCarts();
      }
    }
    if (isNotSignedIn) {
      createLocalEventCart(clinicEvent);
      if (needsRefresh) {
        showInfo('이벤트를 카트에 담았어요');
        fetchLocalEventCarts();
      }
    }
  };

  const setClinicEventsRequested = (clinicEventsRequested: LacteaCallRequest[]) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY_CLINIC_EVENT_REQUESTED, JSON.stringify(clinicEventsRequested));
  };

  return {
    addToEventCart,
    createdEventCart,
    createEventCartLoading,
    fetchRemoteEventCarts,
    eventCartsLoading,
    recommendedEvents,
    setRecommendedEvents,
    localEventCarts,
    setLocalEventCarts,
    remoteEventCarts,
    setRemoteEventCarts,
    setCreatedEventCart,
    setClinicEventsRequested,
    isEventCartAddedAnimation,
    refreshBadgeCount,
    fetchLocalEventCarts,
    hospitalNames,
    setHospitalNames,
  };
};

export const [EventCartProvider, useEventCartContext] = constate(useEventCart);
