import { css } from '@emotion/css';
import Color from 'styles/colors';

export const frame = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 315px;

  background-color: ${Color.White};
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  min-height: 128px;
  &:focus-visible {
    outline: none;
  }
`;

export const content = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 20px 24px 20px;
`;

export const title = css`
  text-align: center;
  word-break: keep-all;
  white-space: pre-line;
`;

export const message = css`
  text-align: center;
  word-break: keep-all;
  margin-top: 16px;
  white-space: pre-line;
`;

export const horizontalButtons = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  gap: 8px;
`;

export const verticalButtons = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 20px 20px 20px;
  gap: 10px;
`;

export const button = (inVertical: boolean) => css`
  ${inVertical ? '' : 'flex: 1;'}
`;
