import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { ClinicEventWithHospitalOutput, ClinicEventStatusEnum } from 'graphql/generated/graphql';
import { Coordinate } from 'model';

dayjs.extend(isBetween);

export function isNumber(value: any): value is number {
  return !!value && !Number.isNaN(Number(value));
}

export function validCoordinateInKorea(coordinate: Coordinate) {
  const { latitude, longitude } = coordinate;
  return latitude && longitude && latitude >= 33 && latitude <= 43 && longitude >= 124 && longitude <= 132;
}

export interface ClinicEvent
  extends Pick<ClinicEventWithHospitalOutput, 'status' | 'startAt' | 'endAt' | 'allowGoodocAd' | 'deletedAt'> {}

export function isLiveClinicEvent(clinicEvent: ClinicEvent) {
  if (
    !clinicEvent.allowGoodocAd ||
    clinicEvent.status !== ClinicEventStatusEnum.Live ||
    clinicEvent?.deletedAt ||
    !clinicEvent?.startAt ||
    !clinicEvent?.endAt
  ) {
    return false;
  }

  const today = dayjs();
  const startAt = dayjs(clinicEvent.startAt);
  const endAt = dayjs(clinicEvent.endAt);
  return today.isBetween(startAt, endAt, 'day', '[]');
}
