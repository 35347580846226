import { IdWise } from './types';

export interface IDictionary<T> {
  [index: string]: T;
}

export function convertIdWiseArrayToDictionary<T extends IdWise>(list: Array<T>): IDictionary<T> {
  const initialDictionary: IDictionary<T> = {};
  return list.reduce((accDictionary: IDictionary<T>, item: T) => {
    // eslint-disable-next-line no-param-reassign
    accDictionary[item.id] = item;
    return accDictionary;
  }, initialDictionary);
}

export function convertObjectFlatten(
  obj: Record<string, any>,
  prefix: string = '',
  separator = '.'
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      const newPrefix = prefix ? `${prefix}${separator}${key}` : key;
      const flattened = convertObjectFlatten(obj[key], newPrefix, separator);
      Object.assign(result, flattened);
    } else {
      const newKey = prefix ? `${prefix}${separator}${key}` : key;
      result[newKey] = obj[key];
    }
  }

  return result;
}

export function convertPaginated<T>(serialList: Array<T>, perPage: number = 3) {
  return serialList?.reduce((acc, curr, i) => {
    const index = Math.floor(i / perPage);
    return [...acc.slice(0, index), [...(acc[index] || []), curr], ...acc.slice(index + 1)];
  }, [] as T[][]);
}
