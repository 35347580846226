import styled, { css } from 'styled-components';
import Color from 'styles/colors';
import { Size, Weight } from 'styles/font';

// TODO 우선은 styled-components로 구현 하였으나 추후에는 emotion으로 변경

const Display1 = styled.h1<{ color?: string }>`
  font-size: ${Size.P30};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Display2 = styled.h2<{ color?: string }>`
  font-size: ${Size.P28};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Head1 = styled.h3<{ color?: string }>`
  font-size: ${Size.P24};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Head2 = styled.h4<{ color?: string }>`
  font-size: ${Size.P20};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Head3 = styled.h5<{ color?: string }>`
  font-size: ${Size.P18};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;

const Head3Medium = styled.h5<{ color?: string }>`
font-size: ${Size.P18};
font-weight: ${Weight.Medium};
line-height: 1.5;
${(props) =>
  css`
    color: ${props?.color || Color.Gray['100']};
  `})}
`;

const HeadNavTitle = styled.h5<{ color?: string }>`
  font-size: ${Size.P18};
  font-weight: ${Weight.Medium};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body1Bold = styled.span<{ color?: string }>`
  font-size: ${Size.P16};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body1Medium = styled.span<{ color?: string }>`
  font-size: ${Size.P16};
  font-weight: ${Weight.Medium};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body1 = styled.span<{ color?: string }>`
  font-size: ${Size.P16};
  font-weight: ${Weight.Regular};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body2Bold = styled.span<{ color?: string }>`
  font-size: ${Size.P14};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body2Medium = styled.span<{ color?: string }>`
  font-size: ${Size.P14};
  font-weight: ${Weight.Medium};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body2 = styled.span<{ color?: string }>`
  font-size: ${Size.P14};
  font-weight: ${Weight.Regular};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body3Bold = styled.span<{ color?: string }>`
  font-size: ${Size.P12};
  font-weight: ${Weight.Bold};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body3Medium = styled.span<{ color?: string }>`
  font-size: ${Size.P12};
  font-weight: ${Weight.Medium};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body3 = styled.span<{ color?: string }>`
  font-size: ${Size.P12};
  font-weight: ${Weight.Regular};
  line-height: 1.5;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body4Medium = styled.span<{ color?: string }>`
  font-size: ${Size.P10};
  font-weight: ${Weight.Medium};
  line-height: 1.4;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;
const Body4 = styled.span<{ color?: string }>`
  font-size: ${Size.P10};
  font-weight: ${Weight.Regular};
  line-height: 1.4;
  ${(props) =>
    css`
      color: ${props?.color || Color.Gray['100']};
    `})}
`;

export default {
  /**
   * @FigmaDefine Heading1_700
   */
  Display1,

  /**
   * @FigmaDefine Heading2_700
   */
  Display2,

  /**
   * @FigmaDefine Heading3_700
   */
  Head1,

  /**
   * @FigmaDefine Heading4_700
   */
  Head2,

  /**
   * @FigmaDefine Heading5_700
   */
  Head3,

  /**
   * @FigmaDefine Heading5_500
   */
  Head3Medium,

  /**
   * @FigmaDefine Heading5_500
   */
  HeadNavTitle,

  /**
   * @FigmaDefine Body1_700
   */
  Body1Bold,

  /**
   * @FigmaDefine Body1_500
   */
  Body1Medium,

  /**
   * @FigmaDefine Body1_400
   */
  Body1,

  /**
   * @FigmaDefine Body2_700
   */
  Body2Bold,

  /**
   * @FigmaDefine Body2_500
   */
  Body2Medium,

  /**
   * @FigmaDefine Body2_400
   */
  Body2,

  /**
   * @FigmaDefine Caption_700
   */
  Body3Bold,

  /**
   * @FigmaDefine Caption_500
   */
  Body3Medium,

  /**
   * @FigmaDefine Caption_400
   */
  Body3,

  /**
   * @FigmaDefine Overline_500
   */
  Body4Medium,

  /**
   * @FigmaDefine Overline_400
   */
  Body4,
};
