import { Modal } from '@material-ui/core';
import Button from 'components/Forms/Button';
import { ButtonSize } from 'components/Forms/Button/types';
import Text from 'components/Typography/Text';
import { FC } from 'react';
import Color from 'styles/colors';
import * as styles from './styles';

export type DialogConfig = {
  title: string;
  message?: string;
  onPrimary?: () => void;
  onAuxiliary?: () => void;
  onDestructive?: () => void;
  primaryText?: string;
  auxiliaryText?: string;
  destructiveText?: string;
};

interface DialogModalProps {
  open: boolean;
  dialogConfig: DialogConfig;
  verticalButtons?: boolean;
  onCleanup?: () => void;
}

const DialogModal: FC<DialogModalProps> = (props) => {
  const { open, dialogConfig, onCleanup, verticalButtons = false } = props;
  return (
    <Modal open={open}>
      <div className={styles.frame}>
        <div className={styles.content}>
          <Text.Head3 className={styles.title}>{dialogConfig.title}</Text.Head3>
          {dialogConfig.message && (
            <Text.Body2 color={Color.Gray[60]} className={styles.message}>
              {dialogConfig.message}
            </Text.Body2>
          )}
        </div>
        <div className={verticalButtons ? styles.verticalButtons : styles.horizontalButtons}>
          {dialogConfig?.onDestructive && (
            <Button
              size={verticalButtons ? ButtonSize.large : ButtonSize.medium}
              styleType="commonFilled"
              className={styles.button(verticalButtons)}
              onClick={() => {
                onCleanup?.();
                dialogConfig.onDestructive?.();
              }}
            >
              {dialogConfig.destructiveText}
            </Button>
          )}
          <Button
            size={verticalButtons ? ButtonSize.large : ButtonSize.medium}
            styleType="primaryFilled"
            className={styles.button(verticalButtons)}
            onClick={() => {
              onCleanup?.();
              dialogConfig.onPrimary?.();
            }}
          >
            {dialogConfig.primaryText}
          </Button>
          {dialogConfig?.onAuxiliary && (
            <Button
              size={verticalButtons ? ButtonSize.large : ButtonSize.medium}
              styleType="primaryOutline"
              className={styles.button(verticalButtons)}
              onClick={() => {
                onCleanup?.();
                dialogConfig.onAuxiliary?.();
              }}
            >
              {dialogConfig.auxiliaryText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DialogModal;
